import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import apiUrl from "../../common/apiUrl";
import Loading from "./components/Loading";
import { NoLoginReferralManager } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  userMenuButton: {
    marginRight: theme.spacing(2),
  },
}));

const ReferralNoLogin = () => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [locationIsValid, setLocationIsValid] = useState(false);
  const [locationId, setLocationId] = useState(null);
  const [locationName, setLocationName] = useState(null);
  const [partnerIsValid, setPartnerIsValid] = useState(false);
  const [partnerId, setPartnerId] = useState(null);

  const { location, partner } = useParams();

  const trimmedPartner = partner ? partner.substring(0, 20) : null;

  /** check challenge status */
  useEffect(() => {
    setIsLoading(() => true);

    const config = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      body: JSON.stringify({
        locationName: location,
      }),
    };

    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/referral/location`, config);

        const json = await response.json();

        if (json) {
          if (json.message === true) {
            setLocationIsValid(() => true);
            setLocationId(() => json.data[0].locationid);
            setLocationName(() => json.data[0].locationname);
            setIsLoading(() => false);
          }
        }
      } catch (error) {
        console.log(error);
        setIsLoading(() => false);
      }
    };
    fetchData();

    if (trimmedPartner !== undefined) {
      const configPartnerFetch = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        body: JSON.stringify({
          partnerAbbr: trimmedPartner,
        }),
      };
      const fetchPartnerId = async () => {
        try {
          const response = await fetch(
            `${apiUrl}/referral/partner`,
            configPartnerFetch
          );

          const json = await response.json();

          if (json) {
            if (json.message === true) {
              setPartnerIsValid(() => true);
              setPartnerId(() => json.data[0].partnerid);
              setIsLoading(() => false);
            }
          }
        } catch (error) {
          console.log(error);
          setIsLoading(() => false);
        }
      };
      fetchPartnerId();
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : locationIsValid === false ? (
        <div className={classes.root}>
          <Grid className={classes.grid} container>
            <Grid item lg={12} xs={10}>
              <div className={classes.contentHeader} />
              <div className={classes.contentBody}>
                <Card className={classes.cardBg} variant="outlined">
                  <CardContent>
                    <div className={classes.logo}>
                      <img alt="Logo" src="/images/logo.png" width="250px" />
                    </div>
                    <br />
                    <Typography
                      component={"div"}
                      className={classes.linkExpired}
                      variant="h5"
                    >
                      This link is invalid. Please check the URL and try again.
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item lg={10} md={12} xl={10} xs={12}>
              <NoLoginReferralManager
                partnerAbbr={trimmedPartner}
                locationId={locationId}
                locationName={locationName}
                referralPartnerId={partnerId}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default ReferralNoLogin;
