import React, { useState, useEffect, useContext, Fragment } from "react";
import { UserContext } from "../../../../UserContext";
import jwt_decode from "jwt-decode";
import AddUserForm from "./AddUserForm";
import EditReferralForm from "./EditReferralForm";
import UserTable from "./UserTable";
import { makeStyles } from "@material-ui/styles";
import { Snackbar, AppBar, Toolbar, Button } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import apiUrl from "../../../../common/apiUrl";

const useStyles = makeStyles(() => ({
  userTable: {
    paddingTop: "2rem",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UserManager = (props) => {
  const classes = useStyles();
  const [users, setUsers] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentReferral, setCurrentReferral] = useState();
  const [editing, setEditing] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [ddls, setDdls] = useState({});

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setOpenErrorSnack(false);
  };

  const { user } = useContext(UserContext);
  const { userId, partnerId } = jwt_decode(user.accessToken);

  const getDdls = async (locId) => {
    setIsLoading(true);

    try {
      const result = await axios.post(apiUrl + "/referral/ddl", {
        userId: userId,
        sendToLocationId: locId,
      });

      setDdls(() => result.data.data[0].spgetdropdowns.dropdowns[0]);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let unmounted = false;
    const fetchData = async () => {
      try {
        const result = await axios.post(apiUrl + "/referral/sent", {
          partnerId: partnerId,
        });
        if (!unmounted) {
          if (props && props.referralId) {
            const paramReferral = result.data.data.find(
              (referral) => referral.apptrequestid === props.referralId
            );
            if (paramReferral) {
              editRow(paramReferral);
              setUsers(result.data.data);
            } else {
              setUsers(result.data.data);
            }
          } else {
            setUsers(result.data.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    return () => {
      // eslint-disable-next-line
      unmounted = true;
    };
  }, []);

  const editRow = async (referral) => {
    await getDdls(referral.locationid);

    setCurrentReferral({
      isValid: true,
      values: {
        apptrequestid: referral.apptrequestid,
        locationId: referral.locationid,
        partnerId: referral.partnerid,
        referringPhysician: referral.referring_physician,
        referringContact: referral.referring_contact,
        referringOfficePhone: referral.referring_office_phone,
        physicianRequested: referral.physician_requested,
        preferredLocation: referral.preferred_location,
        patientContactName: referral.patient_contact_name,
        patientFirstName: referral.patientfirstname,
        patientLastName: referral.lastname,
        gender: referral.gender,
        birthDate: `${referral.birthmonth}/${referral.birthdayofmo}/${referral.birthyear}`,
        ssn: referral.ssn,
        streetAddress: referral.street_address,
        zipCode: referral.zipcode,
        emailAddress: referral.emailaddress,
        primaryPhone: referral.primaryphone,
        referralSourceId: referral.referralsourceid,
        insuranceId: referral.insuranceid,
        insuranceNumber: referral.insnumber,
        bodyPartId: referral.bodypartid,
        complaintId: referral.complaintid,
        acuityId: referral.acuityid,
        previousSurgery: false,
        previousSurgerySide: referral.previous_surgery_side,
        otherInfo: referral.otherinfo,
        requestStatus: referral.requeststatus,
        apptReqState: referral.apptreqstate,
        apptReqStateReason: referral.appt_req_state_reason,
      },
      touched: {},
      errors: {},
      isLoading: false,
    });

    setEditing(true);
  };

  const handleShowAddUser = () => {
    setShowAddUser((showAddUser) => !showAddUser);
  };

  return (
    <>
      <div className="container">
        <Snackbar
          autoHideDuration={6000}
          onClose={handleClose}
          open={openSnack}
        >
          <Alert onClose={handleClose} severity="success">
            Referral updated successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          autoHideDuration={6000}
          onClose={handleClose}
          open={openErrorSnack}
        >
          <Alert onClose={handleClose} severity="error">
            Referral update failed. Please try again.
          </Alert>
        </Snackbar>
        <div className="flex-row">
          <div className="flex-large">
            {editing ? (
              <Fragment>
                <EditReferralForm
                  currentReferral={currentReferral}
                  editing={editing}
                  setEditing={setEditing}
                  ddlData={ddls}
                />
              </Fragment>
            ) : (
              <Fragment>
                {showAddUser && (
                  <AddUserForm
                    showAddUserForm={() => handleShowAddUser()}
                    loading={isLoading}
                  />
                )}
              </Fragment>
            )}
          </div>
          {!editing && (
            <div className={classes.userTable}>
              <UserTable
                currentUserId={userId}
                editRow={editRow}
                referrals={users}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserManager;
