import React, { useState, useEffect, useContext, Fragment } from "react";
import { UserContext } from "../../../../UserContext";
import jwt_decode from "jwt-decode";
import AddUserForm from "./AddLocationForm";
import EditUserForm from "./EditLocationForm";
import UserTable from "./LocationTable";
import { makeStyles } from "@material-ui/styles";
import { Snackbar, AppBar, Toolbar, Button } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import apiUrl from "../../../../common/apiUrl";

const useStyles = makeStyles(() => ({
  userTable: {
    paddingTop: "2rem",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LocationManager = () => {
  const classes = useStyles();

  const initialFormState = {
    id: null,
    first_name: "",
    last_name: "",
    mobile_phone: "",
    email: "",
    password: "",
    role: "",
    isAgent: "",
  };

  const [users, setUsers] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(initialFormState);
  const [editing, setEditing] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setOpenErrorSnack(false);
  };

  const { user } = useContext(UserContext);
  const { userId } = jwt_decode(user.accessToken);

  useEffect(() => {
    let unmounted = false;
    const fetchData = async () => {
      try {
        const result = await axios.post(apiUrl + "/user/list", {
          organizationId: 2,
        });
        setUsers(result.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    return () => {
      // eslint-disable-next-line
      unmounted = true;
    };
  }, []);

  const addUser = async (nUser) => {
    //e.preventDefault();

    setIsLoading(true);

    try {
      const result = await axios.post(apiUrl + "/user", {
        first_name: nUser.first_name,
        last_name: nUser.last_name,
        mobile_phone: nUser.mobile_phone,
        email: nUser.email,
        password: nUser.password,
        role: nUser.role,
        createdBy: nUser.createdBy,
        userLocationId: nUser.locationId,
        userCustomerId: nUser.customerId,
        organizationId: nUser.organizationId,
      });

      setUsers([...users, result.data]);

      setIsLoading(false);
      setOpenSnack(true);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setOpenErrorSnack(true);
      // return error
    }
  };

  const updateUser = async (id, uUser) => {
    setEditing(false);

    setIsLoading(true);

    try {
      const result = await axios.put(apiUrl + "/user", {
        first_name: uUser.first_name,
        last_name: uUser.last_name,
        mobile_phone: uUser.mobile_phone,
        email: uUser.email,
        password: uUser.password,
        role: uUser.role,
        createdBy: uUser.createdBy,
        userLocationId: uUser.locationId,
        userCustomerId: uUser.customerId,
        organizationId: uUser.organizationId,
      });

      if (result.data) {
        setUsers(users.map((user) => (user.id === id ? uUser.values : user)));
      }
      setIsLoading(false);
      setOpenSnack(true);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setOpenErrorSnack(true);
      // return error
    }
  };

  const deleteUser = async (id) => {
    setEditing(false);

    setIsLoading(true);

    try {
      const result = await axios.delete(apiUrl + "/user", {
        userId: id,
      });

      if (result.data) {
        setUsers(users.filter((user) => user.id !== id));
      }

      setIsLoading(false);
      setOpenSnack(true);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setOpenErrorSnack(true);
      // return error
    }
  };

  const editRow = (user) => {
    setEditing(true);

    setCurrentUser({
      isValid: true,
      values: {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        password: user.password,
        role: user.role,
        isAgent: user.is_agent,
      },
      touched: {},
      errors: {},
      isLoading: false,
    });
  };

  const handleShowAddLocation = () => {
    setShowAddUser((showAddUser) => !showAddUser);
  };

  return (
    <>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Button
            onClick={handleShowAddLocation}
            color="primary"
            variant="outlined"
          >
            Create Location
          </Button>
        </Toolbar>
      </AppBar>
      <div className="container">
        <Snackbar
          autoHideDuration={6000}
          onClose={handleClose}
          open={openSnack}
        >
          <Alert onClose={handleClose} severity="success">
            User updated successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          autoHideDuration={6000}
          onClose={handleClose}
          open={openErrorSnack}
        >
          <Alert onClose={handleClose} severity="error">
            User update failed. Please try again.
          </Alert>
        </Snackbar>
        <div className="flex-row">
          <div className="flex-large">
            {editing ? (
              <Fragment>
                <EditUserForm
                  currentUser={currentUser}
                  editing={editing}
                  setEditing={setEditing}
                  updateUser={updateUser}
                />
              </Fragment>
            ) : (
              <Fragment>
                {showAddUser && (
                  <AddUserForm
                    showAddLocationForm={() => handleShowAddLocation()}
                    addUser={addUser}
                    loading={isLoading}
                  />
                )}
              </Fragment>
            )}
          </div>
          <div className={classes.userTable}>
            <UserTable
              currentUserId={userId}
              deleteUser={deleteUser}
              editRow={editRow}
              users={users}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationManager;
