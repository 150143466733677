import React, { useState } from "react";
import { Grid, Tabs, Tab, makeStyles, AppBar } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { UserManager, ReferralManager } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  userMenuButton: {
    marginRight: theme.spacing(2),
  },
}));

const Referral = () => {
  const classes = useStyles();

  // tabs
  const tabNameToIndex = {
    0: "New Referral",
    1: "Referral List",
  };

  const indexToTabName = {
    newReferral: 0,
    referralList: 1,
  };

  /** get referralId if passed via URL and pass to UserManager */
  const { referralId } = useParams();
  const trimmedReferralId = referralId ? referralId.substring(0, 20) : null;

  const [selectedTab, setSelectedTab] = useState(
    indexToTabName[trimmedReferralId ? "referralList" : "newReferral"]
  );
  const [selected, setSelected] = useState(null);

  const updateSelected = (event) => {
    setSelected(event);
  };

  const handleChange = (event, newValue) => {
    // history.push(`/home/${tabNameToIndex[newValue]}`);
    setSelectedTab(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          style={{ paddingBottom: "2rem" }}
        >
          <Tab label="New Referral" />
          <Tab label="Referral List" />
        </Tabs>
      </AppBar>
      {selectedTab === 0 && (
        <Grid container spacing={4}>
          <Grid item lg={10} md={12} xl={10} xs={12}>
            <ReferralManager />
          </Grid>
        </Grid>
      )}
      {selectedTab === 1 && (
        <div>
          <Grid container spacing={4}>
            <Grid item lg={10} md={12} xl={10} xs={12}>
              <UserManager referralId={trimmedReferralId} />
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default Referral;
