import React from "react";
import { Grid, Tabs, Tab, makeStyles } from "@material-ui/core";

// import { UserManager } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Support = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={8} md={6} xl={8} xs={12}>
          Coming Soon
        </Grid>
      </Grid>
    </div>
  );
};

export default Support;
