import React, { useState } from "react";
import { Grid, Tabs, Tab, makeStyles, AppBar } from "@material-ui/core";

// import { UserManager, AccountDetails, AccountPassword } from "./components";

import { UserManager } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  userMenuButton: {
    marginRight: theme.spacing(2),
  },
}));

const Settings = () => {
  const classes = useStyles();

  // tabs
  const tabNameToIndex = {
    0: "Users",
  };

  const indexToTabName = {
    users: 0,
  };

  const [selectedTab, setSelectedTab] = useState(indexToTabName["users"]);
  const [selected, setSelected] = useState(null);

  const updateSelected = (event) => {
    setSelected(event);
  };

  const handleChange = (event, newValue) => {
    // history.push(`/home/${tabNameToIndex[newValue]}`);
    setSelectedTab(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          style={{ paddingBottom: "2rem" }}
        >
          <Tab label="Users" />
        </Tabs>
      </AppBar>
      {selectedTab === 0 && (
        <Grid container spacing={4}>
          <Grid item lg={10} md={12} xl={10} xs={12}>
            <UserManager />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Settings;
