import React, { useState } from "react";
import { DropzoneAreaBase } from "material-ui-dropzone";
import apiUrl from "./apiUrl";

export default function Dropzone(props) {
  const [files, setFiles] = useState([]);
  const [attachmentIds, setAttachmentIds] = useState([]);

  const dbS3Save = async (event) => {
    try {
      const file = event[0].file.path;
      const fileType = event[0].file.type;
      const apptId = props.apptId;
      const fileName = file;

      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        body: JSON.stringify({
          rky: props.rky,
          objectId: fileName,
        }),
      };

      // save locally
      const local = await (
        await fetch(`${apiUrl}/appt/attachment`, config)
      ).json();

      if (local && local.data !== "-1") {
        const attachId = local.data;

        setAttachmentIds((attachmentIds) => [
          ...attachmentIds,
          {
            attachmentId: attachId,
            fileName: fileName,
          },
        ]);

        const configSign = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Cache: "no-cache",
          },
          body: JSON.stringify({
            apptId: apptId,
            objectId: fileName,
            attachId: attachId,
          }),
        };

        const data = await (
          await fetch(`${apiUrl}/appt/get-signed-url`, configSign)
        ).json();

        const formData = new FormData();

        formData.append("Content-Type", fileType);
        Object.entries(data.fields).forEach(([k, v]) => {
          formData.append(k, v);
        });
        formData.append("file", event[0].file);

        await fetch(data.url, {
          method: "POST",
          body: formData,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdd = (newFiles) => {
    newFiles = newFiles.filter(
      (file) => !files.find((f) => f.data === file.data)
    );
    setFiles(() => [...files, ...newFiles]);

    // save to db and s3
    dbS3Save(newFiles);
  };

  const handleDelete = (deleted) => {
    setFiles(() => files.filter((f) => f !== deleted));

    // remove from db and s3
    dbS3Delete(deleted);
  };

  const dbS3Delete = async (fileToDelete) => {
    try {
      const file = fileToDelete.file.path;

      const apptId = props.apptId;
      const fileName = file;

      const attachmentForRemoval = attachmentIds.filter(
        (attachmentId) => attachmentId.fileName === fileName
      );

      const updatedAttachmentIds = attachmentIds.filter(
        (attachmentId) => attachmentId.fileName !== fileName
      );

      setAttachmentIds(() => updatedAttachmentIds);

      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        body: JSON.stringify({
          rky: props.rky,
          objectId: fileName,
          apptId: apptId,
          attachmentId: attachmentForRemoval[0].attachmentId,
        }),
      };

      await (await fetch(`${apiUrl}/appt/rm`, config)).json();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DropzoneAreaBase
      fileObjects={files}
      onAdd={handleAdd}
      onDelete={handleDelete}
      acceptedFiles={["image/*", "application/pdf"]}
      alertSnackbarProps={{
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        autoHideDuration: 5000,
      }}
      dropzoneText={
        "Drag and drop images or PDF files here, or click to select from your system"
      }
      filesLimit={10}
      maxFileSize={10000000}
      clearOnUnmount={true}
    />
  );
}
