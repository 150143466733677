import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    minHeight: "100vh",
    paddingTop: "5%",
    height: "100%",
  },
  content: {
    height: "100%",
  },
}));

const Login = (props) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main className={classes.content}>{children}</main>
    </div>
  );
};

Login.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Login;
