import React, { useContext } from "react";
import { UserContext } from "../../../../UserContext";
import { Link as RouterLink, useHistory } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, Hidden, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import InputIcon from "@material-ui/icons/Input";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  signOutText: {
    color: theme.palette.white,
    padding: theme.spacing(1),
  },
}));

const Topbar = (props) => {
  const { user, setUser } = useContext(UserContext);
  const history = useHistory();

  const currentUser = user.username;

  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  const handleSignOut = async (event) => {
    event.preventDefault();

    try {
      const API_URL =
        process.env.NODE_ENV === "development"
          ? "http://localhost:4000"
          : "https://api.nextdrvisit.com";

      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
          Authorization: `Bearer ${user.accessToken}`,
        },
        credentials: "include",
      };

      fetch(API_URL + "/auth/logout", config);
      setUser({
        username: "",
        isAuthenticated: false,
        accessToken: "",
        tokenExpiresOn: "",
      });
      history.push("/sign-in");
    } catch (error) {
      history.push("/sign-in");
    }
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src="/images/logo-white.png" width="160px" />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <Typography className={classes.signOutText} variant="h5">
            Hi, {currentUser}
          </Typography>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={handleSignOut}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
