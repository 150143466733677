import React, { useState, useMemo, useEffect } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { UserContext } from "./UserContext";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import Routes from "./Routes";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import AccessTokenManager from "./common/accessTokenManager.js";
import validate from "validate.js";
import validators from "./common/validators";
import apiUrl from "./common/apiUrl.js";
import "./mixins/chartjs.js";
import "./App.css";

const browserHistory = createBrowserHistory();

validate.validators = {
  ...validate.validators,
  ...validators,
};

const App = () => {
  const [user, setUser] = useState({
    username: "",
    isAuthenticated: false,
    customerStatus: "",
    accessToken: "",
    tokenExpiresOn: "",
  });
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  /** config axios */
  axios.create({
    baseURL: apiUrl,
    withCredentials: true,
  });

  const AxiosRefreshOptions = {
    pauseInstanceWhileRefreshing: true,
    statusCodes: [401],
  };

  // Function called to refresh authorization
  const refreshAuthLogic = (failedRequest) =>
    AccessTokenManager.getRefreshedToken().then((tokenRefreshResponse) => {
      setUser({
        username: tokenRefreshResponse.username,
        isAuthenticated: tokenRefreshResponse.isAuthenticated,
        customerStatus: tokenRefreshResponse.customerStatus,
        accessToken: tokenRefreshResponse.accessToken,
        tokenExpiresOn: tokenRefreshResponse.tokenExpiresOn,
      });
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + unescape(tokenRefreshResponse.accessToken);
      return Promise.resolve();
    });

  // Instantiate interceptor
  createAuthRefreshInterceptor(axios, refreshAuthLogic, AxiosRefreshOptions);
  /** end config axios */

  useEffect(() => {
    let unmounted = false;
    const fetchData = async () => {
      try {
        const newAccessToken = await AccessTokenManager.getRefreshedToken();

        if (newAccessToken && newAccessToken !== null) {
          setUser({
            username: newAccessToken.username,
            isAuthenticated: newAccessToken.isAuthenticated,
            customerStatus: newAccessToken.customerStatus,
            accessToken: newAccessToken.accessToken,
            tokenExpiresOn: newAccessToken.tokenExpiresOn,
          });
        } else {
          browserHistory.push("/");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    return () => {
      // eslint-disable-next-line
      unmounted = true;
    };
  }, []);

  return (
    <UserContext.Provider value={value}>
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </ThemeProvider>
    </UserContext.Provider>
  );
};

export default App;
