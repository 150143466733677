import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../../UserContext";
import jwt_decode from "jwt-decode";
import validate from "validate.js";
import { makeStyles } from "@material-ui/styles";
import {
  Snackbar,
  Button,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Card,
  CardContent,
  InputAdornment,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import axios from "axios";
import apiUrl from "../../../../common/apiUrl";
import Loading from "./Loading";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Dropzone from "../../../../common/Dropzone";

const schema = {
  locationId: {
    presence: true,
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: "is required",
    },
  },
  preferredLocation: {
    presence: true,
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: "is required",
    },
  },
  insuranceId: {
    presence: true,
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: "is required",
    },
  },
  bodyPartId: {
    presence: true,
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: "is required",
    },
  },
  complaintId: {
    presence: true,
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: "is required",
    },
  },
  acuityId: {
    presence: true,
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: "is required",
    },
  },
  referringPhysician: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  referringContact: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  referringOfficePhone: {
    presence: {
      allowEmpty: false,
      message: "is required in the format 123-456-7890",
    },
    length: {
      minimum: 12,
    },
    format: {
      pattern: "^(\\+0?1\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$",
      flags: "i",
      message: "is required in the format 123-456-7890",
    },
  },
  patientFirstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  patientLastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  streetAddress: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 2,
      maximum: 150,
    },
  },
  zipCode: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 5,
      maximum: 5,
    },
    format: {
      pattern: "[0-9]+",
      flags: "i",
      message: "can only contain 0-9",
    },
  },
  primaryPhone: {
    presence: {
      allowEmpty: false,
      message: "is required in the format 123-456-7890",
    },
    length: {
      minimum: 12,
    },
    format: {
      pattern: "^(\\+0?1\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$",
      flags: "i",
      message: "is required in the format 123-456-7890",
    },
  },
  ssn: {
    length: {
      maximum: 11,
    },
  },
  birthDate: {
    presence: {
      allowEmpty: false,
      message: "is required",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  userTable: {
    paddingTop: "2rem",
  },
  input: {
    background: "white",
  },
  grid: {
    spacing: 0,
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(7),
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  linkExpired: {
    paddingTop: theme.spacing(4),
    textAlign: "center",
    marginBottom: "6em",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ReferralManager = () => {
  const classes = useStyles();

  const initialState = {
    isValid: false,
    values: {
      userId: "",
      locationId: "",
      partnerId: "",
      referringPhysician: "",
      referringContact: "",
      referringOfficePhone: "",
      physicianRequested: "",
      preferredLocation: "",
      preferredApptTime: "",
      patientContactName: "",
      patientFirstName: "",
      patientLastName: "",
      gender: "",
      birthDate: null,
      ssn: "",
      streetAddress: "",
      zipCode: "",
      emailAddress: "",
      primaryPhone: "",
      referralSourceId: "",
      insuranceId: "",
      insuranceNumber: "",
      bodyPartId: "",
      complaintId: "",
      acuityId: "",
      previousSurgery: false,
      previousSurgerySide: "",
      attachmentPath: "",
      attachmentFName: "",
      otherInfo: "",
      referralContactEmail: "",
    },
    touched: {},
    errors: {},
    isLoading: false,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [severity] = useState("");
  const [message] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [ddls, setDdls] = useState({});
  const [partnerLocations, setPartnerLocations] = useState();
  const [currentComplaint, setCurrentComplaint] = useState(1);
  const [apptId, setApptId] = useState();
  const [rky, setRky] = useState();
  const [locationAbbr, setLocationAbbr] = useState();
  const [formState, setFormState] = useState(initialState);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setOpenErrorSnack(false);
  };

  const { user } = useContext(UserContext);
  const { userId, partnerId } = jwt_decode(user.accessToken);

  useEffect(() => {
    const fetchPartnerLocations = async () => {
      setIsLoading(true);

      try {
        const result = await axios.post(
          apiUrl + "/referral/partner-locations",
          {
            partnerId: partnerId,
          }
        );

        setPartnerLocations(() => result.data.data);

        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchPartnerLocations();
  }, [partnerId]);

  useEffect(() => {
    let unmounted = false;

    if (
      formState.values.locationId !== "" &&
      formState.values.locationId !== true
    ) {
      const getDdls = async () => {
        //e.preventDefault();

        setIsLoading(true);

        try {
          const result = await axios.post(apiUrl + "/referral/ddl", {
            userId: userId,
            sendToLocationId: formState.values.locationId,
          });

          if (result && !unmounted) {
            setDdls(() => result.data.data[0].spgetdropdowns.dropdowns[0]);
          }

          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      };

      getDdls();
    }

    return () => {
      unmounted = true;
    };
  }, [formState.values.locationId, userId]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  useEffect(() => {
    let unmounted = false;

    const fetchUserDetails = async () => {
      try {
        const result = await axios.post(apiUrl + "/user/detail", {
          userId: userId,
        });

        if (result && !unmounted && formState.values.locationId !== "") {
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              referringContact:
                `${result.data.data[0].first_name} ${result.data.data[0].last_name}` ||
                "",
              referralContactEmail: result.data.data[0].email || "",
            },
          }));
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserDetails();

    return () => {
      unmounted = true;
    };
  }, [formState.values.locationId, userId]);

  const sendReferral = async () => {
    setIsLoading(true);

    let unmounted = false;

    try {
      const result = await axios.post(apiUrl + "/referral/send", {
        userId: userId,
        locationId: formState.values.locationId,
        partnerId: partnerId,
        referringPhysician: formState.values.referringPhysician,
        referringContact: formState.values.referringContact,
        referringOfficePhone: formState.values.referringOfficePhone,
        physicianRequested: formState.values.physicianRequested,
        preferredLocation: formState.values.preferredLocation,
        preferredApptTime: 1,
        patientContactName: formState.values.patientContactName,
        patientFirstName: formState.values.patientFirstName,
        patientLastName: formState.values.patientLastName,
        gender: formState.values.gender,
        birthDate: formState.values.birthDate,
        ssn: formState.values.ssn,
        streetAddress: formState.values.streetAddress,
        zipCode: formState.values.zipCode,
        emailAddress: formState.values.emailAddress,
        primaryPhone: formState.values.primaryPhone,
        // referralSourceId: formState.values.referralSourceId,
        referralSourceId: 12,
        insuranceId: formState.values.insuranceId,
        insuranceNumber: formState.values.insuranceNumber,
        bodyPartId: formState.values.bodyPartId,
        complaintId: formState.values.complaintId,
        acuityId: formState.values.acuityId,
        previousSurgery: formState.values.previousSurgery,
        previousSurgerySide: formState.values.previousSurgerySide,
        attachmentPath: formState.values.attachmentPath,
        attachmentFName: formState.values.attachmentFName,
        otherInfo: formState.values.otherInfo,
        referralContactEmail: formState.values.referralContactEmail,
      });

      if (result && !unmounted) {
        setApptId(() => result.data.data[0].rsapptrequestid);
        setRky(() => result.data.data[0].rky);
        setLocationAbbr(() => result.data.data[0].locationabbr);
        setIsLoading(false);
      }

      setOpenSnack(true);
      setSent(() => true);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setOpenErrorSnack(true);
      setSent(() => false);
    }
    return () => {
      unmounted = true;
    };
  };

  const resetReferralForm = () => {
    setFormState(() => initialState);
    setSent(() => false);
  };

  const handleChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));

    if (event.target.name === "bodyPartId") {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          complaintId: "",
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true,
        },
      }));
    }
  };

  const handlePhoneChange = (event) => {
    event.persist();

    var x = event.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = event.target.value = !x[2]
      ? x[1]
      : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox" ? event.target.checked : x,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSSNChange = (event) => {
    event.persist();

    var x = event.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
    x = event.target.value = !x[2]
      ? x[1]
      : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox" ? event.target.checked : x,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleDateChange = (date) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        birthDate: date,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <>
      {/* <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              label="Choose Location to Send Referral"
              name="referralLocation"
              onChange={handleChange}
              required
              select
              type="text"
              value={formState.values.referralLocation || false}
              variant="outlined"
              InputProps={{
                className: classes.input,
              }}
            >
              <MenuItem selected value={false}>
                MSMOC
              </MenuItem>
              <MenuItem value></MenuItem>
            </TextField>
          </Grid>
        </Toolbar>
      </AppBar> */}
      {isLoading ? (
        <Loading />
      ) : sent === false ? (
        <div className="container">
          <br />
          <Snackbar
            autoHideDuration={6000}
            onClose={handleClose}
            open={openSnack}
          >
            <Alert onClose={handleClose} severity="success">
              Referral sent successfully!
            </Alert>
          </Snackbar>
          <Snackbar
            autoHideDuration={6000}
            onClose={handleClose}
            open={openErrorSnack}
          >
            <Alert onClose={handleClose} severity="error">
              Failed to send referral. Please try again.
            </Alert>
          </Snackbar>
          <div className="flex-row">
            <div className="flex-large">
              <form>
                <Grid className={classes.grid} spacing={2} container>
                  <Grid item md={12} xs={12}>
                    <Typography
                      className={classes.prompt}
                      align="center"
                      variant="h4"
                    >
                      Choose A Location To Send Your Referral
                    </Typography>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      helperText={
                        hasError("locationId")
                          ? formState.errors.locationId[0]
                          : null
                      }
                      label="Refer to Location:"
                      name="locationId"
                      onChange={handleChange}
                      required
                      select
                      type="text"
                      value={formState.values.locationId}
                      variant="outlined"
                      InputProps={{
                        className: classes.input,
                      }}
                    >
                      {!partnerLocations ? (
                        <MenuItem key={"z"} value={null}>
                          {"Please Select"}
                        </MenuItem>
                      ) : (
                        partnerLocations.map((location) => (
                          <MenuItem
                            key={location.locationid + "z"}
                            value={location.locationid}
                          >
                            {location.locationname}
                          </MenuItem>
                        ))
                      )}
                    </TextField>
                  </Grid>

                  {formState.values.locationId !== "" &&
                  formState.values.locationId !== true ? (
                    <>
                      <Grid item md={12} xs={12}>
                        <Typography
                          className={classes.prompt}
                          align="center"
                          variant="h4"
                        >
                          Referrer Information
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={hasError("referringPhysician")}
                          required
                          fullWidth
                          helperText={
                            hasError("referringPhysician")
                              ? formState.errors.referringPhysician[0]
                              : null
                          }
                          label="Referring Physician"
                          name="referringPhysician"
                          onChange={handleChange}
                          type="text"
                          value={formState.values.referringPhysician || ""}
                          variant="outlined"
                          InputProps={{
                            className: classes.input,
                          }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={hasError("referringContact")}
                          fullWidth
                          required
                          helperText={
                            hasError("referringContact")
                              ? formState.errors.referringContact[0]
                              : null
                          }
                          label="Referring Contact"
                          name="referringContact"
                          onChange={handleChange}
                          type="text"
                          value={formState.values.referringContact || ""}
                          variant="outlined"
                          InputProps={{
                            className: classes.input,
                          }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={hasError("referringOfficePhone")}
                          fullWidth
                          required
                          helperText={
                            hasError("referringOfficePhone")
                              ? formState.errors.referringOfficePhone[0]
                              : null
                          }
                          label="Referring Office Phone"
                          name="referringOfficePhone"
                          onChange={handlePhoneChange}
                          type="text"
                          value={formState.values.referringOfficePhone || ""}
                          variant="outlined"
                          InputProps={{
                            className: classes.input,
                          }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={hasError("referralContactEmail")}
                          fullWidth
                          helperText={
                            hasError("referralContactEmail")
                              ? formState.errors.referralContactEmail[0]
                              : null
                          }
                          label="Referring Contact Email (Required for status updates)"
                          name="referralContactEmail"
                          onChange={handleChange}
                          type="text"
                          value={formState.values.referralContactEmail || ""}
                          variant="outlined"
                          inputProps={{ maxLength: 75 }}
                          InputProps={{
                            className: classes.input,
                          }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Physician Requested"
                          name="physicianRequested"
                          onChange={handleChange}
                          select
                          type="text"
                          value={formState.values.physicianRequested}
                          variant="outlined"
                          InputProps={{
                            className: classes.input,
                          }}
                        >
                          {ddls.docs ? (
                            ddls.docs.map((doc) => (
                              <MenuItem
                                key={doc.providerid + "a"}
                                value={doc.providerid}
                              >
                                {doc.providername}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem key={"c"} value={null}>
                              {"Please Select"}
                            </MenuItem>
                          )}
                        </TextField>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Preferred Location"
                          name="preferredLocation"
                          onChange={handleChange}
                          required
                          select
                          type="text"
                          value={formState.values.preferredLocation}
                          variant="outlined"
                          InputProps={{
                            className: classes.input,
                          }}
                        >
                          {ddls.sites ? (
                            ddls.sites.map((site) => (
                              <MenuItem
                                key={site.siteid + "b"}
                                value={site.siteid}
                              >
                                {site.sitename}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem key={"b"} value={null}>
                              {"Please Select"}
                            </MenuItem>
                          )}
                        </TextField>
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Typography
                          className={classes.prompt}
                          align="center"
                          variant="h4"
                        >
                          Patient Information
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={hasError("patientContactName")}
                          required
                          fullWidth
                          helperText={
                            hasError("patientContactName")
                              ? formState.errors.patientContactName[0]
                              : null
                          }
                          label="Patient Contact Name"
                          name="patientContactName"
                          onChange={handleChange}
                          type="text"
                          value={formState.values.patientContactName || ""}
                          variant="outlined"
                          InputProps={{
                            className: classes.input,
                          }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={hasError("primaryPhone")}
                          fullWidth
                          required
                          helperText={
                            hasError("primaryPhone")
                              ? formState.errors.primaryPhone[0]
                              : null
                          }
                          label="Patient Primary Phone"
                          name="primaryPhone"
                          onChange={handlePhoneChange}
                          type="text"
                          value={formState.values.primaryPhone || ""}
                          variant="outlined"
                          InputProps={{
                            className: classes.input,
                          }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={hasError("patientFirstName")}
                          required
                          fullWidth
                          helperText={
                            hasError("patientFirstName")
                              ? formState.errors.patientFirstName[0]
                              : null
                          }
                          label="Patient First Name"
                          name="patientFirstName"
                          onChange={handleChange}
                          type="text"
                          value={formState.values.patientFirstName || ""}
                          variant="outlined"
                          InputProps={{
                            className: classes.input,
                          }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={hasError("patientLastName")}
                          required
                          fullWidth
                          helperText={
                            hasError("patientLastName")
                              ? formState.errors.patientLastName[0]
                              : null
                          }
                          label="Patient Last Name"
                          name="patientLastName"
                          onChange={handleChange}
                          type="text"
                          value={formState.values.patientLastName || ""}
                          variant="outlined"
                          InputProps={{
                            className: classes.input,
                          }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Patient Gender"
                          name="gender"
                          onChange={handleChange}
                          required
                          select
                          type="text"
                          value={formState.values.gender}
                          variant="outlined"
                          InputProps={{
                            className: classes.input,
                          }}
                        >
                          <MenuItem value={"male"}>Male</MenuItem>
                          <MenuItem value={"female"}>Female</MenuItem>
                        </TextField>
                      </Grid>

                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item md={6} xs={12}>
                          <KeyboardDatePicker
                            fullWidth
                            disableToolbar
                            clearable
                            required
                            variant="dialog"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            id="birthDate"
                            label="Patient Date of Birth"
                            maxDate={Date.now()}
                            minDate="01/01/1900"
                            openTo="year"
                            value={formState.values.birthDate || ""}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            InputProps={{
                              className: classes.input,
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider> */}

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item md={6} xs={12}>
                          <DatePicker
                            error={hasError("birthDate")}
                            helperText={
                              hasError("birthDate")
                                ? formState.errors.birthDate[0]
                                : null
                            }
                            fullWidth
                            disableToolbar
                            required
                            variant="dialog"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            id="birthDate"
                            label="Patient Date of Birth"
                            maxDate={Date.now()}
                            minDate="01/01/1900"
                            openTo="year"
                            value={formState.values.birthDate || null}
                            onChange={handleDateChange}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              className: classes.input,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <DateRangeIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={hasError("ssn")}
                          fullWidth
                          helperText={
                            hasError("ssn") ? formState.errors.ssn[0] : null
                          }
                          label="Patient Social Security Number"
                          name="ssn"
                          onChange={handleSSNChange}
                          type="text"
                          value={formState.values.ssn || ""}
                          variant="outlined"
                          inputProps={{ maxLength: 11 }}
                          InputProps={{
                            className: classes.input,
                          }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={hasError("streetAddress")}
                          fullWidth
                          required
                          helperText={
                            hasError("streetAddress")
                              ? formState.errors.streetAddress[0]
                              : null
                          }
                          label="Patient Street Address"
                          name="streetAddress"
                          onChange={handleChange}
                          type="text"
                          value={formState.values.streetAddress || ""}
                          variant="outlined"
                          InputProps={{
                            className: classes.input,
                          }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={hasError("zipCode")}
                          required
                          fullWidth
                          helperText={
                            hasError("zipCode")
                              ? formState.errors.zipCode[0]
                              : null
                          }
                          label="Patient Zip Code"
                          name="zipCode"
                          onChange={handleChange}
                          type="text"
                          value={formState.values.zipCode || ""}
                          variant="outlined"
                          inputProps={{ maxLength: 5 }}
                          InputProps={{
                            className: classes.input,
                          }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={hasError("emailAddress")}
                          fullWidth
                          helperText={
                            hasError("emailAddress")
                              ? formState.errors.emailAddress[0]
                              : null
                          }
                          label="Patient Email Address"
                          name="emailAddress"
                          onChange={handleChange}
                          type="text"
                          value={formState.values.emailAddress || ""}
                          variant="outlined"
                          inputProps={{ maxLength: 75 }}
                          InputProps={{
                            className: classes.input,
                          }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Insurance Company"
                          name="insuranceId"
                          onChange={handleChange}
                          required
                          select
                          type="text"
                          value={formState.values.insuranceId}
                          variant="outlined"
                          InputProps={{
                            className: classes.input,
                          }}
                        >
                          {ddls.insurances ? (
                            ddls.insurances.map((insurance) => (
                              <MenuItem
                                key={insurance.insuranceid + "c"}
                                value={insurance.insuranceid}
                              >
                                {insurance.insurance}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem key={"c"} value={null}>
                              {"Please Select"}
                            </MenuItem>
                          )}
                        </TextField>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          error={hasError("insuranceNumber")}
                          fullWidth
                          helperText={
                            hasError("insuranceNumber")
                              ? formState.errors.insuranceNumber[0]
                              : null
                          }
                          label="Insurance Policy Number"
                          name="insuranceNumber"
                          onChange={handleChange}
                          type="text"
                          value={formState.values.insuranceNumber || ""}
                          variant="outlined"
                          inputProps={{ maxLength: 75 }}
                          InputProps={{
                            className: classes.input,
                          }}
                        />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Typography
                          className={classes.prompt}
                          align="center"
                          variant="h4"
                        >
                          Condition Details
                        </Typography>
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <TextField
                          fullWidth
                          label="Body Part Affected"
                          name="bodyPartId"
                          onChange={(e) => {
                            handleChange(e);
                            setCurrentComplaint(() => e.target.value);
                          }}
                          required
                          select
                          type="text"
                          value={formState.values.bodyPartId}
                          variant="outlined"
                          InputProps={{
                            className: classes.input,
                          }}
                        >
                          {ddls.bodyparts ? (
                            ddls.bodyparts.map((bodypart) => (
                              <MenuItem
                                key={bodypart.bodypartid + "d"}
                                value={bodypart.bodypartid}
                              >
                                {bodypart.bodypartname}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem key={"d"} value={null}>
                              {"Please Select"}
                            </MenuItem>
                          )}
                        </TextField>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          disabled={!formState.values.bodyPartId}
                          label="Reason For The Appointment?"
                          name="complaintId"
                          onChange={handleChange}
                          required
                          select
                          type="text"
                          value={formState.values.complaintId}
                          variant="outlined"
                          InputProps={{
                            className: classes.input,
                          }}
                        >
                          {ddls.complaints ? (
                            ddls.complaints
                              .filter(
                                (unfilteredComplaints) =>
                                  unfilteredComplaints.bodypartid ===
                                  currentComplaint
                              )
                              .map((complaint) => (
                                <MenuItem
                                  key={complaint.complaintid + "e"}
                                  value={complaint.complaintid}
                                >
                                  {complaint.complaintname}
                                </MenuItem>
                              ))
                          ) : (
                            <MenuItem key={"e"} value={null}>
                              {"Please Select"}
                            </MenuItem>
                          )}
                        </TextField>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Duration Of Complaint"
                          name="acuityId"
                          onChange={handleChange}
                          required
                          select
                          type="text"
                          value={formState.values.acuityId}
                          variant="outlined"
                          InputProps={{
                            className: classes.input,
                          }}
                        >
                          {ddls.acuitys ? (
                            ddls.acuitys.map((acuity) => (
                              <MenuItem
                                key={acuity.acuityid + "f"}
                                value={acuity.acuityid}
                              >
                                {acuity.acuityname}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem key={"f"} value={null}>
                              {"Please Select"}
                            </MenuItem>
                          )}
                        </TextField>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Previous Surgery On This Body Part?"
                          name="previousSurgery"
                          onChange={handleChange}
                          required
                          select
                          type="text"
                          value={formState.values.previousSurgery}
                          variant="outlined"
                          InputProps={{
                            className: classes.input,
                          }}
                        >
                          <MenuItem value={false}>No</MenuItem>
                          <MenuItem value={true}>Yes</MenuItem>
                        </TextField>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="If previous surgery, which side of the body?"
                          name="previousSurgerySide"
                          onChange={handleChange}
                          required
                          disabled={!formState.values.previousSurgery}
                          select
                          type="text"
                          value={formState.values.previousSurgerySide}
                          variant="outlined"
                          InputProps={{
                            className: classes.input,
                          }}
                        >
                          <MenuItem value={"left"}>Left</MenuItem>
                          <MenuItem value={"right"}>Right</MenuItem>
                        </TextField>
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          rows="4"
                          label="Anything Else We Should Know?"
                          name="otherInfo"
                          onChange={handleChange}
                          type="text"
                          value={formState.values.otherInfo || ""}
                          variant="outlined"
                          InputProps={{
                            className: classes.input,
                          }}
                        />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Button
                          color="primary"
                          onClick={sendReferral}
                          fullWidth
                          disabled={!formState.isValid}
                          variant="contained"
                        >
                          Send Referral And Add Attachments
                        </Button>
                      </Grid>

                      {message && (
                        <div className={classes.resMessage}>
                          <Alert severity={severity}>{message}</Alert>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Grid className={classes.grid} container>
            <Grid item lg={12} xs={10}>
              <div className={classes.contentHeader} />
              <div className={classes.contentBody}>
                <Card className={classes.cardBg} variant="outlined">
                  <CardContent>
                    <Grid item md={12} xs={12}>
                      <div className={classes.logo}>
                        <ThumbUpIcon color="primary" fontSize="large" />
                      </div>
                      <Typography
                        component={"div"}
                        className={classes.linkExpired}
                        variant="h4"
                      >
                        Your referral was sent successfully!
                      </Typography>
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Typography
                        className={classes.prompt}
                        align="center"
                        variant="h4"
                      >
                        Attach Documents To The Referral
                      </Typography>
                    </Grid>

                    <Grid container>
                      <Grid item md={12} xs={12}>
                        <Dropzone apptId={apptId} rky={rky} />
                      </Grid>
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Button
                        color="primary"
                        onClick={resetReferralForm}
                        fullWidth
                        variant="contained"
                      >
                        I'm Finished!
                      </Button>
                    </Grid>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default ReferralManager;
