import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { RouteWithLayout, AuthenticatedRoute } from "./components";
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Login as LoginLayout,
  NoLoginReferralLayout as NoLoginLayout,
} from "./layouts";

import {
  // Dashboard as DashboardView,
  Referral as ReferralView,
  ReferralNoLogin as ReferralNoLoginView,
  NotFound as NotFoundView,
  SignIn as SignInView,
  ForgotPassword as ForgotPasswordView,
  ResetPassword as ResetPasswordView,
  Settings as SettingsView,
  // Support as SupportView,
} from "./views";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/referral" />
      {/* <AuthenticatedRoute
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      /> */}
      <RouteWithLayout
        component={ReferralNoLoginView}
        layout={NoLoginLayout}
        path="/r/:location/:partner?"
      />
      <AuthenticatedRoute
        component={ReferralView}
        exact
        layout={MainLayout}
        path="/referral/:referralId?"
      />
      <AuthenticatedRoute
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      {/* <AuthenticatedRoute
        component={SupportView}
        exact
        layout={MainLayout}
        path="/support"
      /> */}
      <RouteWithLayout
        component={SignInView}
        exact
        layout={LoginLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={ForgotPasswordView}
        exact
        layout={LoginLayout}
        path="/forgot-password"
      />
      <RouteWithLayout
        component={ResetPasswordView}
        exact
        layout={LoginLayout}
        path="/reset-password/:token"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
