import palette from "./palette";

const typography = {
  h1: {
    color: palette.text.primary,
    fontWeight: 500,
    // fontSize: "35px",
    fontSize: "2.188rem",
    letterSpacing: "-0.24px",
    // //lineHeight: "40px",
    //lineHeight: "2.5rem",
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 500,
    // fontSize: "29px",
    fontSize: "1.813rem",
    letterSpacing: "-0.24px",
    // //lineHeight: "32px",
    //lineHeight: "2rem",
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 500,
    // fontSize: "24px",
    fontSize: "1.5rem",
    letterSpacing: "-0.06px",
    // //lineHeight: "28px",
    //lineHeight: "1.75rem",
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 500,
    // fontSize: "20px",
    fontSize: "1.25rem",
    letterSpacing: "-0.06px",
    // //lineHeight: "24px",
    //lineHeight: "1.5rem",
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 500,
    // fontSize: "16px",
    fontSize: "1rem",
    letterSpacing: "-0.05px",
    // //lineHeight: "20px",
    //lineHeight: "1.25rem",
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 500,
    // fontSize: "14px",
    fontSize: "0.875rem",
    letterSpacing: "-0.05px",
    // //lineHeight: "20px",
    //lineHeight: "1.25rem",
  },
  subtitle1: {
    color: palette.text.primary,
    // fontSize: "16px",
    fontSize: "1rem",
    letterSpacing: "-0.05px",
    // //lineHeight: "25px",
    //lineHeight: "1.563rem",
  },
  subtitle2: {
    color: palette.text.secondary,
    fontWeight: 400,
    //fontSize: "14px",
    fontSize: "0.875rem",
    letterSpacing: "-0.05px",
    // //lineHeight: "21px",
    //lineHeight: "1.313rem",
  },
  body1: {
    color: palette.text.primary,
    // fontSize: "14px",
    fontSize: "0.875rem",
    letterSpacing: "-0.05px",
    // //lineHeight: "21px",
    //lineHeight: "1.313",
  },
  body2: {
    color: palette.text.secondary,
    // fontSize: "12px",
    fontSize: "0.75rem",
    letterSpacing: "-0.04px",
    // //lineHeight: "18px",
    //lineHeight: "1.125rem",
  },
  button: {
    color: palette.text.primary,
    // fontSize: "14px",
    fontSize: "0.875rem",
  },
  caption: {
    color: palette.text.secondary,
    //fontSize: "11px",
    fontSize: "0.688rem",
    letterSpacing: "0.33px",
    // //lineHeight: "13px",
    //lineHeight: "0.813rem",
  },
  overline: {
    color: palette.text.secondary,
    //fontSize: "11px",
    fontSize: "0.688rem",
    fontWeight: 500,
    letterSpacing: "0.33px",
    // //lineHeight: "13px",
    //lineHeight: "0.813rem",
    textTransform: "uppercase",
  },
};

export default typography;
