import axios from "axios";
import apiUrl from "./apiUrl.js";

const accessTokenManager = () => {
  let accessToken = null;
  // let isRefreshing = null;
  // let refreshTimeOutId;

  const getRefreshedToken = async () => {
    try {
      const res = await fetch(apiUrl + "/auth/refresh", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        credentials: "include",
      });

      const newAccessToken = await res.json();

      if (newAccessToken !== "") {
        setToken(newAccessToken.accessToken, newAccessToken.tokenExpiresOn);
        return newAccessToken;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const setToken = (token) => {
    accessToken = token;
    // refreshToken(delay);
    return true;
  };

  axios.interceptors.request.use(
    async (config) => {
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error && error.response && error.response.status === 401) {
        // 401 error redirect to login
        return Promise.reject(error);
      }
      if (error.response.status !== 401) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );

  // const refreshToken = (delay) => {
  //   refreshTimeOutId = window.setTimeout(
  //     getRefreshedToken,
  //     delay * 1000 - 5000
  //   ); // Validity period of the token in seconds, minus 5 seconds
  // };

  // const abortRefreshToken = () => {
  //   if (refreshTimeOutId) {
  //     window.clearTimeout(refreshTimeOutId);
  //   }
  // };

  // const waitForTokenRefresh = () => {
  //   if (!isRefreshing) {
  //     return Promise.resolve();
  //   }
  //   return isRefreshing.then(() => {
  //     isRefreshing = null;
  //     return true;
  //   });
  // };

  // const request = new Request(refreshEndpoint, {
  //   method: "POST",
  //   headers: new Headers({ "Content-Type": "application/json" }),
  //   credentials: "include",
  // });

  // isRefreshing = fetch(request)
  //   .then((response) => {
  //     if (response.status !== 200) {
  //       eraseToken();
  //       global.console.log("Token renewal failure");
  //       return { token: null };
  //     }
  //     //console.log(response.json());
  //     return response.json();
  //   })
  //   .then(({ token, tokenExpiry }) => {
  //     if (token) {
  //       console.log(`token: ${JSON.stringify(token)}`);
  //       setToken(token, tokenExpiry);
  //       return true;
  //     }
  //     eraseToken();
  //     return false;
  //   });

  // return isRefreshing;

  // const getToken = () => accessToken;

  // Response interceptor for API calls
  // axios.interceptors.response.use(
  //   (response) => {
  //     return response;
  //   },
  //   async function (error) {
  //     const originalRequest = error.config;
  //     if (error.response.status === 401 && !originalRequest._retry) {
  //       originalRequest._retry = true;
  //       const access_token = await getRefreshedToken();
  //       axios.defaults.headers.common["Authorization"] =
  //         "Bearer " + access_token;
  //       return axios(originalRequest);
  //     }
  //     return Promise.reject(error);
  //   }
  // );

  // const eraseToken = () => {
  //   accessToken = null;
  //   abortRefreshToken();
  //   return true;
  // };

  return {
    getRefreshedToken,
    setToken,
    apiUrl,
  };
};

export default accessTokenManager();
