import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@material-ui/core";
import PageviewIcon from "@material-ui/icons/Pageview";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  header: {
    color: "#FFFFFF",
    textAlign: "left",
    padding: "1rem",
    fontSize: "1rem",
    fontWeight: 600,
  },
  tableHead: {
    backgroundColor: theme.palette.primary.main,
  },
  tableRow: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const UserTable = (props) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.header}></TableCell>
            {/* <TableCell className={classes.header}>Date</TableCell> */}
            <TableCell className={classes.header}>Referral ID</TableCell>
            <TableCell className={classes.header}>Patient First Name</TableCell>
            <TableCell className={classes.header}>Patient Last Name</TableCell>
            <TableCell className={classes.header}>
              Referring Physician
            </TableCell>
            <TableCell className={classes.header}>Referring Contact</TableCell>
            <TableCell className={classes.header}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.referrals && props.referrals.length > 0 ? (
            props.referrals.map((referral, index) => (
              <TableRow key={index} className={classes.tableRow}>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      props.editRow(referral);
                    }}
                    type="submit"
                  >
                    <PageviewIcon />
                  </IconButton>
                </TableCell>

                {/* <TableCell>{referral.dtcreated}</TableCell> */}
                <TableCell>{referral.apptrequestid}</TableCell>
                <TableCell>{referral.patientfirstname}</TableCell>
                <TableCell>{referral.lastname}</TableCell>
                <TableCell>{referral.referring_physician}</TableCell>
                <TableCell>{referral.referring_contact}</TableCell>
                <TableCell>
                  {referral.apptReqState === 1
                    ? "Active"
                    : referral.apptReqState === 2
                    ? "Pending"
                    : "Complete"}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3}>Click above to send referrals!</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
