import React, { useState, useContext, useEffect } from 'react';
import validate from 'validate.js';
import PropTypes from 'prop-types';
import { fetchData } from '../../../../common/fetchData';
import clsx from 'clsx';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { UserContext } from '../../../../UserContext';
import { makeStyles } from '@material-ui/styles';
import jwt_decode from 'jwt-decode';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core';

const schema = {
  currentPassword: {
    presence: true,
    length: {
      minimum: 8,
      maximum: 100
    }
  },
  newPassword: {
    presence: true,
    format: {
      pattern:
        '^(?=[ -~]*?[A-Z])(?=[ -~]*?[a-z])(?=[ -~]*?[0-9])(?=[ -~]*?[#?!@$%^&*-])[ -~]{8,72}$',
      flags: 'i',
      message:
        'requires a minimum eight characters, at least one uppercase letter, one lowercase letter, one number, and one special character'
    }
  },
  confirm: {
    presence: true,
    equality: {
      attribute: 'newPassword',
      message: '^The passwords do not match'
    }
  }
};

const useStyles = makeStyles(() => ({
  root: {}
}));

function Alert(props) {
  return <MuiAlert
    elevation={6}
    variant="filled"
    {...props}
  />;
}

const AccountPassword = props => {
  const [openSnack, setOpenSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
    setOpenErrorSnack(false);
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      currentPassword: '',
      newPassword: '',
      confirm: ''
    },
    touched: {},
    errors: {},
    isLoading: false
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const { user, setUser } = useContext(UserContext);
  const { userId } = jwt_decode(user.accessToken);

  const handleSubmit = async e => {
    e.preventDefault();

    setFormState(formState => ({
      ...formState,
      isLoading: true
    }));

    try {
      const { data, newUser } = await fetchData(
        '/user/pw',
        user.accessToken,
        'PUT',
        {
          userId: userId,
          currentPassword: formState.values.currentPassword,
          newPassword: formState.values.newPassword
        }
      );

      if (data) {
        setFormState(formState => ({
          ...formState,
          isLoading: false
        }));
      }
      setOpenSnack(true);

      if (newUser) {
        setUser(newUser);
      }
    } catch (e) {
      setFormState(formState => ({
        ...formState,
        isLoading: false
      }));
      setOpenErrorSnack(true);
      return e;
    }
  };

  const { className, ...rest } = props;

  const classes = useStyles();

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div>
      <Snackbar
        autoHideDuration={6000}
        onClose={handleClose}
        open={openSnack}
      >
        <Alert
          onClose={handleClose}
          severity="success"
        >
          Password updated successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={6000}
        onClose={handleClose}
        open={openErrorSnack}
      >
        <Alert
          onClose={handleClose}
          severity="error"
        >
          Password update failed. Please try again.
        </Alert>
      </Snackbar>

      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form onSubmit={handleSubmit}>
          <CardHeader
            subheader="Update Password"
            title="Password"
          />
          <Divider />
          <CardContent>
            <TextField
              error={hasError('currentPassword')}
              fullWidth
              helperText={
                hasError('currentPassword')
                  ? formState.errors.currentPassword[0]
                  : null
              }
              label="Current Password"
              margin="dense"
              name="currentPassword"
              onChange={handleChange}
              required
              type="password"
              value={formState.values.currentPassword}
              variant="outlined"
            />
            <TextField
              error={hasError('newPassword')}
              fullWidth
              helperText={
                hasError('newPassword') ? formState.errors.newPassword[0] : null
              }
              label="New Password"
              margin="dense"
              name="newPassword"
              onChange={handleChange}
              required
              style={{ marginTop: '1rem' }}
              type="password"
              value={formState.values.newPassword}
              variant="outlined"
            />
            <TextField
              error={hasError('confirm')}
              fullWidth
              helperText={
                hasError('confirm') ? formState.errors.confirm[0] : null
              }
              label="Confirm New Password"
              margin="dense"
              name="confirm"
              onChange={handleChange}
              required
              style={{ marginTop: '1rem' }}
              type="password"
              value={formState.values.confirm}
              variant="outlined"
            />
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              disabled={!formState.isValid || formState.isLoading}
              type="submit"
              variant="outlined"
            >
              Update Password
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  );
};

AccountPassword.propTypes = {
  className: PropTypes.string
};

export default AccountPassword;
