import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  TextField,
  Grid,
  Button,
  Card,
  Divider,
  CardContent,
  CardActions,
  CardHeader,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  userTable: {
    paddingTop: "2rem",
  },
  input: {
    background: "white",
  },
  grid: {
    spacing: 0,
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(7),
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  linkExpired: {
    paddingTop: theme.spacing(4),
  },
}));

const EditReferralForm = (props) => {
  const history = useHistory();
  const [referral, setReferral] = useState(props.currentReferral);
  const ddls = props.ddlData;

  // get dropdown values
  const currentDoc = ddls.docs.find(
    (doc) => doc.providerid === referral.values.physicianRequested
  );

  const currentLocation = ddls.sites.find(
    (site) => site.siteid === referral.values.preferredLocation
  );

  const currentInsurance = ddls.insurances.find(
    (insurance) => insurance.insuranceid === referral.values.insuranceId
  );

  const currentBodyPart = ddls.bodyparts.find(
    (bodypart) => bodypart.bodypartid === referral.values.bodyPartId
  );

  const currentComplaint = ddls.complaints.find(
    (bodypart) => bodypart.bodypartid === referral.values.complaintId
  );

  const currentAcuity = ddls.acuitys.find(
    (acuity) => acuity.acuityid === referral.values.acuityId
  );

  const currentReferredLocation = ddls.locations.find(
    (location) => location.locationid === referral.values.locationId
  );

  const classes = useStyles();

  useEffect(() => {
    setReferral(props.currentReferral);
  }, [props]);

  const handleClose = () => {
    props.setEditing(false);
    history.push("/referral");
  };

  return (
    <Card>
      <CardHeader
        subheader="View the details of the referral below"
        title="Referral Details"
      />
      <Divider />
      <CardContent>
        <div className="flex-row">
          <div className="flex-large">
            <form>
              <Grid className={classes.grid} spacing={2} container>
                <Grid item md={12} xs={12}>
                  <Typography
                    className={classes.prompt}
                    align="center"
                    variant="h4"
                  >
                    Referred to Location
                  </Typography>
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Referred to Location"
                    name="locationId"
                    type="text"
                    value={
                      (currentReferredLocation &&
                        currentReferredLocation.location) ||
                      referral.values.locationId
                    }
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Typography
                    className={classes.prompt}
                    align="center"
                    variant="h4"
                  >
                    Referrer Information
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Referring Physician"
                    name="referringPhysician"
                    type="text"
                    value={referral.values.referringPhysician || ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Referring Contact"
                    name="referringContact"
                    type="text"
                    value={referral.values.referringContact || ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Referring Office Phone"
                    name="referringOfficePhone"
                    type="text"
                    value={referral.values.referringOfficePhone || ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Physician Requested"
                    name="physicianRequested"
                    type="text"
                    value={currentDoc ? currentDoc.providername : ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Preferred Location"
                    name="preferredLocation"
                    required
                    type="text"
                    value={currentLocation ? currentLocation.sitename : ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Typography
                    className={classes.prompt}
                    align="center"
                    variant="h4"
                  >
                    Patient Information
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Patient Contact Name"
                    name="patientContactName"
                    type="text"
                    value={referral.values.patientContactName || ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Patient Primary Phone"
                    name="primaryPhone"
                    type="text"
                    value={referral.values.primaryPhone || ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Patient First Name"
                    name="patientFirstName"
                    type="text"
                    value={referral.values.patientFirstName || ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Patient Last Name"
                    name="patientLastName"
                    type="text"
                    value={referral.values.patientLastName || ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Patient Gender"
                    name="gender"
                    required
                    type="text"
                    value={referral.values.gender || ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Patient Date of Birth"
                    name="birthDate"
                    required
                    type="text"
                    value={referral.values.birthDate || ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Patient Social Security Number"
                    name="ssn"
                    type="text"
                    value={referral.values.ssn || ""}
                    variant="outlined"
                    inputProps={{ maxLength: 11 }}
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Patient Street Address"
                    name="streetAddress"
                    type="text"
                    value={referral.values.streetAddress || ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Patient Zip Code"
                    name="zipCode"
                    type="text"
                    value={referral.values.zipCode || ""}
                    variant="outlined"
                    inputProps={{ maxLength: 5 }}
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Patient Email Address"
                    name="emailAddress"
                    type="text"
                    value={referral.values.emailAddress || ""}
                    variant="outlined"
                    inputProps={{ maxLength: 75 }}
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Insurance Company"
                    name="insuranceId"
                    required
                    type="text"
                    value={currentInsurance ? currentInsurance.insurance : ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Insurance Policy Number"
                    name="insuranceNumber"
                    type="text"
                    value={referral.values.insuranceNumber || ""}
                    variant="outlined"
                    inputProps={{ maxLength: 75 }}
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Typography
                    className={classes.prompt}
                    align="center"
                    variant="h4"
                  >
                    Condition Details
                  </Typography>
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Body Part Affected"
                    name="bodyPartId"
                    required
                    type="text"
                    value={
                      (currentBodyPart && currentBodyPart.bodypartname) ||
                      referral.values.bodyPartId
                    }
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Reason For The Appointment?"
                    name="complaintId"
                    required
                    type="text"
                    value={
                      (currentComplaint && currentComplaint.complaintname) ||
                      referral.values.complaintId
                    }
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  ></TextField>
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Duration Of Complaint"
                    name="acuityId"
                    required
                    type="text"
                    value={
                      (currentAcuity && currentAcuity.acuityname) ||
                      referral.values.acuityId
                    }
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Previous Surgery On This Body Part?"
                    name="previousSurgery"
                    required
                    type="text"
                    value={referral.values.previousSurgery}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="If previous surgery, which side of the body?"
                    name="previousSurgerySide"
                    required
                    type="text"
                    value={referral.values.previousSurgerySide}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows="4"
                    label="Anything Else We Should Know?"
                    name="otherInfo"
                    type="text"
                    value={referral.values.otherInfo || ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography
                    className={classes.prompt}
                    align="center"
                    variant="h4"
                  >
                    Referral Status
                  </Typography>
                </Grid>
                {referral && referral?.values?.apptReqStateReason && (
                  <>
                    <Grid item md={12} xs={12}>
                      <Typography
                        className={classes.prompt}
                        align="center"
                        variant="h4"
                      >
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            label="Referral Status (Final)"
                            name="locationId"
                            type="text"
                            value={referral.values.apptReqStateReason}
                            variant="outlined"
                            InputProps={{
                              className: classes.input,
                            }}
                            multiline
                            maxRows={4}
                          />
                        </Grid>
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </div>
        </div>
      </CardContent>
      <CardActions>
        <Button color="primary" onClick={handleClose} variant="contained">
          Close
        </Button>
      </CardActions>
    </Card>
  );
};

export default EditReferralForm;
