import React, { useContext } from "react";
import { UserContext } from "../../../../UserContext";
import { Link as RouterLink, useHistory } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  signOutText: {
    color: theme.palette.white,
    padding: theme.spacing(1),
  },
}));

const Topbar = (props) => {
  const { user, setUser } = useContext(UserContext);
  const history = useHistory();


  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();


  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src="/images/logo-white.png" width="160px" />
        </RouterLink>
        <div className={classes.flexGrow} />
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
