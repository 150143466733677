import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Button,
  Card,
  Divider,
  CardContent,
  CardActions,
  CardHeader,
  MenuItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import validate from "validate.js";

const schema = {
  first_name: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 20,
    },
    format: {
      pattern: "[a-z0-9]+",
      flags: "i",
      message: "can only contain a-z and 0-9",
    },
  },
  last_name: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 20,
    },
    format: {
      pattern: "[a-z0-9]+",
      flags: "i",
      message: "can only contain a-z and 0-9",
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  mobile_phone: {
    presence: {
      allowEmpty: false,
      message: "is required in the format 123-456-7890",
    },
    length: {
      minimum: 12,
    },
    format: {
      pattern: "^(\\+0?1\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$",
      flags: "i",
      message: "is required in the format 123-456-7890",
    },
  },
  password: {
    presence: true,
    format: {
      pattern:
        "^(?=[ -~]*?[A-Z])(?=[ -~]*?[a-z])(?=[ -~]*?[0-9])(?=[ -~]*?[#?!@$%^&*-])[ -~]{8,72}$",
      flags: "i",
      message:
        "requires a minimum eight characters, at least one uppercase letter, one lowercase letter, one number, and one special character",
    },
  },
  confirm: {
    presence: true,
    equality: {
      attribute: "password",
      message: "<- The passwords do not match",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  cardHeaderTitle: {
    color: "#FFFFFF",
    fontSize: "1rem",
    fontWeight: 600,
  },
  cardSubHeaderTitle: {
    color: "#FFFFFF",
  },
  cardHeaderBackground: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const AddLocationForm = (props) => {
  const classes = useStyles();

  const initialFormState = {
    isValid: false,
    values: {
      locationName: "",
      phone: "",
      city: "",
      state: "",
      zip: "",
      locationAbbr: "",
      locationUrl: "",
    },
    touched: {},
    errors: {},
    isLoading: false,
  };

  const [location, setLocation] = useState(initialFormState);

  useEffect(() => {
    const errors = validate(location.values, schema);

    setLocation((location) => ({
      ...location,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [location.values]);

  const handleInputChange = (event) => {
    event.persist();

    setLocation((location) => ({
      ...location,
      values: {
        ...location.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...location.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handlePhoneChange = (event) => {
    event.persist();

    var x = event.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = event.target.value = !x[2]
      ? x[1]
      : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

    setLocation((location) => ({
      ...location,
      values: {
        ...location.values,
        [event.target.name]:
          event.target.type === "checkbox" ? event.target.checked : x,
      },
      touched: {
        ...location.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    props.addUser(location.values);
    setLocation(initialFormState);
  };

  const hasError = (field) =>
    location.touched[field] && location.errors[field] ? true : false;

  return (
    <Card>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <CardHeader
          disableTypography
          title={
            <Typography className={classes.cardHeaderTitle} type="body1">
              Create A New Location
            </Typography>
          }
          subheader={
            <Typography className={classes.cardSubHeaderTitle} type="body1">
              Only admins can manage locations
            </Typography>
          }
          className={classes.cardHeaderBackground}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("locationName")}
                fullWidth
                helperText={
                  hasError("locationName")
                    ? location.errors.locationName[0]
                    : null
                }
                label="Location Name"
                margin="dense"
                name="locationName"
                onChange={handleInputChange}
                required
                type="text"
                value={location.values.locationName || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("locationAbbr")}
                fullWidth
                helperText={
                  hasError("locationAbbr")
                    ? location.errors.locationAbbr[0]
                    : null
                }
                label="Location Abbreviation"
                margin="dense"
                name="locationAbbr"
                onChange={handleInputChange}
                required
                type="text"
                value={location.values.locationAbbr || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("locationUrl")}
                fullWidth
                helperText={
                  hasError("locationUrl")
                    ? location.errors.locationUrl[0]
                    : null
                }
                label="Location URL"
                margin="dense"
                name="locationUrl"
                onChange={handleInputChange}
                required
                type="text"
                value={location.values.locationUrl || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("mobile_phone")}
                fullWidth
                helperText={
                  hasError("mobile_phone")
                    ? location.errors.mobile_phone[0]
                    : null
                }
                inputProps={{ maxLength: 12 }}
                label="Primary Location Phone"
                margin="dense"
                name="mobile_phone"
                onChange={handlePhoneChange}
                required
                type="text"
                value={location.values.mobile_phone || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("city")}
                fullWidth
                helperText={hasError("city") ? location.errors.city[0] : null}
                label="City"
                margin="dense"
                name="city"
                onChange={handleInputChange}
                required
                type="text"
                value={location.values.city || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("st")}
                fullWidth
                helperText={hasError("st") ? location.errors.st[0] : null}
                label="State"
                margin="dense"
                name="st"
                onChange={handleInputChange}
                required
                type="text"
                value={location.values.st || ""}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Zip Code"
                margin="dense"
                name="zip"
                onChange={handleInputChange}
                required
                type="text"
                value={location.values.zip || ""}
                variant="outlined"
              ></TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Agent"
                margin="dense"
                name="isAgent"
                onChange={handleInputChange}
                required
                select
                type="text"
                value={location.values.isAgent || false}
                variant="outlined"
              >
                <MenuItem selected value={false}>
                  No
                </MenuItem>
                <MenuItem value>Yes</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            disabled={!location.isValid || props.loading}
            type="submit"
            variant="contained"
          >
            Create User
          </Button>
          <Button
            color="primary"
            onClick={() => props.showAddLocationForm()}
            variant="contained"
          >
            Cancel
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default AddLocationForm;
