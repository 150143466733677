import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import validate from "validate.js";
import PropTypes from "prop-types";
import apiUrl from "../../common/apiUrl.js";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Button,
  TextField,
  Grid,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const schema = {
  newPassword: {
    presence: true,
    format: {
      pattern:
        "^(?=[ -~]*?[A-Z])(?=[ -~]*?[a-z])(?=[ -~]*?[0-9])(?=[ -~]*?[#?()_+=~`!@$%^&*-])[ -~]{8,72}$",
      flags: "i",
      message:
        "requires a minimum of eight characters, at least one uppercase letter, one lowercase letter, one number, and one special character",
    },
  },
  confirm: {
    presence: true,
    equality: {
      attribute: "newPassword",
      message: "^The passwords do not match",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  grid: {
    spacing: 0,
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(7),
  },
  cardBg: {
    backgroundColor: theme.palette.background.default,
    borderWidth: "2px",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 50,
    flexBasis: 500,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    fontFamily: "Source Sans Pro",
    fontWeight: 900,
  },
  input: {
    background: "white",
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    margin: theme.spacing(4, 0),
  },
  resMessage: {
    marginTop: theme.spacing(2),
  },
  logo: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  linkExpired: {
    paddingTop: theme.spacing(4),
  },
}));

const ResetPassword = (props) => {
  const classes = useStyles();

  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [link, setLink] = useState("");
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      newPassword: "",
      confirm: "",
    },
    touched: {},
    errors: {},
    isTokenValid: false,
    isLoading: false,
  });

  let history = useHistory();
  const { token } = useParams();

  const testAlphaNumeric = new RegExp(/^[a-z0-9]+$/i);
  const isAlpha = testAlphaNumeric.test(token);
  const tokenLength = token.length;

  if (!isAlpha || !tokenLength === 64) {
    history.push("/sign-in");
  }

  useEffect(() => {
    const config = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        resetToken: token,
      }),
    };

    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/auth/validate-token`, config);
        const tokenData = await response.json();

        if (tokenData.data === "") {
          setFormState((formState) => ({
            ...formState,
            isTokenValid: false,
          }));
        } else {
          setFormState((formState) => ({
            ...formState,
            isTokenValid: true,
          }));
        }
      } catch (error) {
        setFormState((formState) => ({
          ...formState,
          isTokenValid: false,
        }));
      }
    };
    fetchData();
  }, [token, history]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFormState((formState) => ({
      ...formState,
      isLoading: true,
    }));

    try {
      const res = await fetch(apiUrl + "/auth/reset-password", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        credentials: "include",
        body: JSON.stringify({
          resetToken: token,
          password: formState.values.newPassword,
        }),
      });

      const data = await res.json();

      if (data) {
        setFormState((formState) => ({
          ...formState,
          isLoading: false,
        }));
        setSeverity(data.severity);
        setMessage(data.message);
        if (data.link) {
          setLink(data.link);
        }
      }
    } catch (error) {
      setFormState((formState) => ({
        ...formState,
        isLoading: false,
      }));

      setSeverity("error");
      setMessage(error.message);

      return error;
    }
  };

  const handleExpiredSubmit = async (e) => {
    e.preventDefault();
    history.push("/forgot-password");
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div>
      {!formState.isTokenValid ? (
        <div className={classes.root}>
          <Grid className={classes.grid} container>
            <Grid item lg={12} xs={10}>
              <div className={classes.contentHeader} />
              <div className={classes.contentBody}>
                <Card className={classes.cardBg} variant="outlined">
                  <CardContent>
                    <div className={classes.logo}>
                      <img alt="Logo" src="/images/logo.png" width="250px" />
                    </div>
                    <Typography className={classes.linkExpired} variant="h5">
                      Your password reset link has expired. Please click the
                      button below to send an updated reset link.
                    </Typography>
                    <form onSubmit={handleExpiredSubmit}>
                      <Button
                        className={classes.submitButton}
                        color="primary"
                        disabled={formState.isLoading}
                        type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                      >
                        Send New Reset Email
                      </Button>
                    </form>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={classes.root}>
          <Grid className={classes.grid} container>
            <Grid item xl={3} lg={6} sm={6} xs={10}>
              <Card className={classes.cardBg} variant="outlined">
                <CardContent>
                  <div className={classes.logo}>
                    <img alt="Logo" src="/images/logo.png" width="250px" />
                  </div>
                  <form onSubmit={handleSubmit}>
                    <Typography className={classes.title} variant="h2">
                      Create New Password
                    </Typography>
                    <TextField
                      error={hasError("newPassword")}
                      fullWidth
                      helperText={
                        hasError("newPassword")
                          ? formState.errors.newPassword[0]
                          : null
                      }
                      label="New Password"
                      name="newPassword"
                      onChange={handleChange}
                      required
                      style={{ marginTop: "1rem" }}
                      type="password"
                      value={formState.values.newPassword}
                      variant="outlined"
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                    <TextField
                      error={hasError("confirm")}
                      fullWidth
                      helperText={
                        hasError("confirm") ? formState.errors.confirm[0] : null
                      }
                      label="Confirm New Password"
                      name="confirm"
                      onChange={handleChange}
                      required
                      style={{ marginTop: "1rem" }}
                      type="password"
                      value={formState.values.confirm}
                      variant="outlined"
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                    {message && (
                      <div className={classes.resMessage}>
                        <Alert severity={severity}>
                          {message}
                          {link !== "" ? (
                            <a href={"https://referral.nextdrvisit.com"}>
                              Click here to Sign In
                            </a>
                          ) : (
                            ""
                          )}
                        </Alert>
                      </div>
                    )}
                    <Button
                      className={classes.submitButton}
                      color="primary"
                      disabled={
                        !formState.isValid ||
                        formState.isLoading ||
                        severity === "success"
                      }
                      type="submit"
                      fullWidth
                      size="large"
                      variant="contained"
                    >
                      Update Password
                    </Button>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

ResetPassword.propTypes = {
  className: PropTypes.string,
};

export default ResetPassword;
