import React, { useState, useContext, useEffect } from 'react';
import { useFetch } from '../../../../common/useFetch';
import { fetchData } from '../../../../common/fetchData';
import { UserContext } from '../../../../UserContext';
import validate from 'validate.js';
import clsx from 'clsx';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import jwt_decode from 'jwt-decode';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';

const schema = {
  firstName: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 20
    },
    format: {
      pattern: '[a-z0-9]+',
      flags: 'i',
      message: 'can only contain a-z and 0-9'
    }
  },
  lastName: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 20
    },
    format: {
      pattern: '[a-z0-9]+',
      flags: 'i',
      message: 'can only contain a-z and 0-9'
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  phone: {
    presence: { allowEmpty: false, message: 'is required 123-456-7890' },
    length: {
      maximum: 12
    }
  }
};

const useStyles = makeStyles(() => ({
  root: {}
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AccountDetails = (props) => {
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      role: '',
      coach: ''
    },
    touched: {},
    errors: {},
    isLoading: false
  });

  const [openSnack, setOpenSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
    setOpenErrorSnack(false);
  };

  const { user, setUser } = useContext(UserContext);
  const { userId } = jwt_decode(user.accessToken);

  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
      Authorization: `Bearer ${user.accessToken}`
    },
    credentials: 'include',
    body: JSON.stringify({
      currentUserId: userId
    })
  };

  const { data } = useFetch('/user/me', config);

  useEffect(() => {
    if (data) {
      setFormState((formState) => ({
        ...formState,
        values: {
          firstName: data.user.first_name,
          lastName: data.user.last_name,
          email: data.user.email,
          phone: data.user.mobile_phone
        }
      }));
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFormState((formState) => ({
      ...formState,
      isLoading: true
    }));

    try {
      const { data, newUser } = await fetchData(
        '/user',
        user.accessToken,
        'PUT',
        {
          userId: userId,
          first_name: formState.values.firstName,
          last_name: formState.values.lastName,
          email: formState.values.email,
          mobile_phone: formState.values.phone
        }
      );

      if (data) {
        setFormState((formState) => ({
          ...formState,
          values: {
            firstName: data.user.first_name,
            lastName: data.user.last_name,
            email: data.user.email,
            phone: data.user.mobile_phone
          },
          isLoading: false
        }));
      }
      setOpenSnack(true);

      if (newUser) {
        setUser(newUser);
      }
    } catch (e) {
      setFormState((formState) => ({
        ...formState,
        isLoading: false
      }));
      setOpenErrorSnack(true);
      return e;
    }
  };

  const { className, ...rest } = props;

  const classes = useStyles();

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div>
      <Snackbar autoHideDuration={6000} onClose={handleClose} open={openSnack}>
        <Alert onClose={handleClose} severity="success">
          User updated successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={6000}
        onClose={handleClose}
        open={openErrorSnack}>
        <Alert onClose={handleClose} severity="error">
          User update failed. Please try again.
        </Alert>
      </Snackbar>

      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <CardHeader
            subheader="Use the form below to update your profile"
            title="User Profile"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  error={hasError('firstName')}
                  fullWidth
                  helperText={
                    hasError('firstName') ? formState.errors.firstName[0] : null
                  }
                  label="First name"
                  margin="dense"
                  name="firstName"
                  onChange={handleChange}
                  required
                  value={formState.values.firstName || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  error={hasError('lastName')}
                  fullWidth
                  helperText={
                    hasError('lastName') ? formState.errors.lastName[0] : null
                  }
                  label="Last name"
                  margin="dense"
                  name="lastName"
                  onChange={handleChange}
                  required
                  value={formState.values.lastName || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Email Address"
                  margin="dense"
                  name="email"
                  onChange={handleChange}
                  required
                  value={formState.values.email || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  error={hasError('phone')}
                  fullWidth
                  helperText={
                    hasError('phone') ? formState.errors.phone[0] : null
                  }
                  label="Phone Number"
                  margin="dense"
                  name="phone"
                  onChange={handleChange}
                  type="tel"
                  value={formState.values.phone || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              disabled={!formState.isValid || formState.isLoading}
              type="submit"
              variant="contained">
              Update Profile
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
